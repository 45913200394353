<template>
  <v-dialog
    :value="openedValue"
    max-width="400px"
    @click:outside="close()"
    @keydown.esc="close()"
  >
    <v-card
      :loading="loading"
    >
      <v-card-title>Indiquer la date de livraison</v-card-title>
      <v-card-text>
        <v-alert
          outlined
          type="warning"
        >
          Toutes les pièces associées à cet envoi verront leur date de rétractation calculée en fonction de celle-ci.
        </v-alert>
        <v-row
          justify="center"
          class="py-2"
          no-gutters
        >
          <v-date-picker
            v-model="delivered_at"
            full-width
            show-adjacent-months
            elevation="2"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          outlined
          color="red darken-2"
          @click="close()"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>{{ $t('forms.buttons.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          raised
          color="green darken-1"
          class="white--text"
          @click="save()"
        >
          <v-icon left>
            mdi-check-outline
          </v-icon>{{ $t('forms.buttons.validate') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'DeliverModal',
  mixins: [ snackbarMixin ],
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    shipmentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      delivered_at: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      message: {
        type: null,
        text: null,
      },
    }
  },
  computed: {
    openedValue: {
      get() { return this.opened },
      set(value) { this.$emit('update:opened', value) },
    },
  },
  methods: {
    close() {
      this.loading = false
      this.openedValue = false
    },
    async save() {
      this.loading = true

      try {
        const shipment = (await this.$axios.post('v3/shipments/' + this.shipmentId + '/delivers', {
          delivered_at: this.delivered_at,
        })).data
        this.snackbarSuccess(this.$i18n.t('views.shipment.forms.messages.save_succeed'))
        this.$emit('success', shipment)
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message }))
      } finally {
        this.close()
      }
    },
  },
}
</script>

<style scoped>

</style>
