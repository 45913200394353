<template>
  <v-card
    :loading="loading"
  >
    <v-card-title>
      {{ title }}
      <v-spacer />
      <ButtonAction
        :disabled="disabled"
        :action="openEditModal"
        text="Modifier"
      />
    </v-card-title>
    <v-card-text>
      <AddressDetail
        :address="address"
        :email="email"
        :phone="phone"
      />
    </v-card-text>
    <AddressModal
      :address="address"
      :email="email"
      :opened.sync="modal.opened"
      :phone="phone"
      :title="$t('views.shipment.forms.title.address_update.' + type)"
      :type="type"
      @submited="save"
    />
  </v-card>
</template>

<script>
import AddressDetail from '@/components/core/AddressDetail'
import AddressModal from '@/views/Shipments/Edit/AddressModal.vue'
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import snackbarMixin from '@/mixins/snackbar'

export default {
  name: 'Address',
  components: { ButtonAction, AddressDetail, AddressModal },
  mixins: [ snackbarMixin ],
  props: {
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    shipmentId: {
      type: String,
      required: true,
    },
    address: {
      type: Object,
      default: () => ({}),
    },
    email: {
      type: String,
      default: null,
    },
    phone: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      message: {
        type: null,
        text: null,
      },
      modal: {
        opened: false,
      },
    }
  },
  methods: {
    openEditModal() {
      this.modal.opened = true
    },
    async save(data) {
      if (!['recipient', 'sender'].includes(this.type)) {
        return
      }
      this.loading = true

      try {
        const shipment = (await this.$axios.put('v3/shipments/' + this.shipmentId + '/' + this.type, data)).data
        this.$emit('success', shipment)
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message }))
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
