<template>
  <Page
    :mounted.sync="mounted"
  >
    <template v-slot:title>
      <v-icon class="mr-2">
        mdi-package-variant
      </v-icon>
      <template v-if="shipment.status">
        {{ $t('views.shipment.enum.type.' + shipment.type) }}
        <v-icon
          v-if="shipment.warehouse?.internal"
          class="mx-2"
          color="orange"
        >
          mdi-home-account
        </v-icon>
        :
        <span
          v-if="shipment.tracking_number"
          class="font-weight-light mx-2"
        >
          {{ shipment.tracking_number }}
        </span>
        <StatusChip
          v-if="shipment.status"
          :status="shipment.status"
        />
      </template>
    </template>
    
    <template v-slot:toolbar-buttons>
      <ButtonAction
        :disabled="!capacities.refreshStatus"
        :action="refresh"
        icon="mdi-timer-refresh-outline"
        text="Actualiser"
      />
      <ButtonAction
        :disabled="!capacities.updateDelivery"
        :action="openDeliverModal"
        icon="mdi-package-variant-closed-check"
        text="Livré ?"
      />
      <ButtonAction
        :disabled="!capacities.lost"
        :action="markAsLost"
        icon="mdi-package-variant-remove"
        text="Perdu ?"
      />
    </template>
    
    <template v-slot:content>
      <v-card
        color="transparent"
        flat
      >
        <v-overlay
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            size="64"
          />
        </v-overlay>
        <v-card-text>
          <v-row
            v-if="shipment.last_error"
            dense
          >
            <v-col>
              <v-alert
                border="left"
                class="mb-0"
                dense
                icon="mdi-alert-box"
                prominent
                text
                type="error"
              >
                {{ shipment.last_error.created_at | date }} - {{ shipment.last_error.message }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-if="shipment.deleted_at"
            dense
          >
            <v-col>
              <v-alert
                border="left"
                class="mb-0"
                dense
                icon="mdi-trash-can"
                prominent
                text
                type="error"
              >
                {{ shipment.deleted_at | date }}: Supprimé
              </v-alert>
            </v-col>
          </v-row>
          <v-row
            v-if="shipment.lost"
            dense
          >
            <v-col>
              <v-alert
                border="left"
                class="mb-0"
                dense
                icon="mdi-truck-alert"
                prominent
                text
                type="error"
              >
                {{ shipment.lost_on | date }}: Colis déclaré perdu
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <Address
                title="Expéditeur"
                type="sender"
                :shipment-id="shipment.id"
                :address="shipment.sender_address"
                :email="shipment.sender_email"
                :phone="shipment.sender_phone"
                :disabled="!capacities.updateSender"
                @success="s => shipment = s"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="12"
            >
              <Address
                title="Destinataire"
                type="recipient"
                :shipment-id="shipment.id"
                :address="shipment.recipient_address"
                :email="shipment.recipient_email"
                :phone="shipment.recipient_phone"
                :disabled="!capacities.updateRecipient"
                @suceess="(s) => shipment = s"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  Détail
                  <v-spacer />
                  <ButtonAction
                    :action="openProviderModal"
                    text="Modifier"
                  />
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                      sm="12"
                    >
                      <v-simple-table
                        class="lh_shipment__details"
                      >
                        <tbody>
                          <tr>
                            <td>
                              Logisticien
                            </td>
                            <td>
                              <ProviderChip :provider="shipment.provider" />
                              <v-btn
                                v-if="shipment.provider_reference"
                                :href="getProviderLink(shipment)"
                                color="blue lighten-1"
                                class="mx-2"
                                icon
                                target="_blank"
                              >
                                <v-icon small>
                                  mdi-open-in-new
                                </v-icon>
                              </v-btn>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Transporteur
                            </td>
                            <td>
                              <CarrierChip :carrier="shipment.carrier" />
                              <v-icon
                                v-if="isCarrierTracked(shipment.carrier) === false"
                                class="ml-4"
                                color="orange darken-1"
                                small
                                title="Ce transporteur ne bénéficie pas de la mise à jour de statut"
                              >
                                mdi-sync-off
                              </v-icon>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              N° de suivi
                            </td>
                            <td>
                              <TrackingNumberLink
                                :tracking-number="shipment.tracking_number"
                                :tracking-url="shipment.tracking_url"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Référence
                            </td>
                            <td>{{ shipment.reference }}</td>
                          </tr>
                          <tr>
                            <td>
                              Date de création
                            </td>
                            <td>{{ shipment.created_datetime | date }}</td>
                          </tr>
                          <tr>
                            <td>
                              Date de livraison
                            </td>
                            <td>
                              {{ shipment.delivered_datetime | date }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Poids (g)
                            </td>
                            <td>{{ shipment.weight }}</td>
                          </tr>
                          <tr>
                            <td>
                              Note
                            </td>
                            <td>
                              <Notes
                                v-if="shipment.id"
                                :id="shipment.id"
                                :type="'Shipment'"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </v-simple-table>
                    </v-col>
                    <v-col
                      cols="12"
                      md="4"
                      sm="12"
                    >
                      <v-list
                        dense
                        style="text-align: right"
                      >
                        <v-list-item
                          v-if="capacities.createParcel"
                        >
                          <v-list-item-title>
                            <v-btn
                              text
                              small
                              color="blue lighten-1"
                              @click="createParcel()"
                            >
                              Créer le colis
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="capacities.createLabel"
                        >
                          <v-list-item-title>
                            <v-btn
                              text
                              small
                              color="blue lighten-1"
                              @click="createLabel()"
                            >
                              Créer le bordereau
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item
                          v-if="capacities.downloadLabel"
                        >
                          <v-list-item-title>
                            <v-btn
                              target="_blank"
                              :href="shipment.label_url"
                              text
                              small
                              color="blue lighten-1"
                            >
                              Télecharger le bordereau
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-title>
                            <v-btn
                              target="_blank"
                              :href="getProformaInvoiceUrl(shipment)"
                              text
                              small
                              color="blue lighten-1"
                            >
                              Télecharger la facture proforma
                            </v-btn>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-card>
                <v-card-title>
                  Articles
                  <v-spacer />
                  <ButtonAction
                    :disabled="mode.reorganize"
                    :action="activeReorganizeMode"
                    icon="mdi-vector-polyline-edit"
                    text="Réorganiser"
                  />
                </v-card-title>
                <v-card-text>
                  <v-data-iterator
                    v-model="selected"
                    :items="shipment.order_items"
                    item-key="id"
                    hide-default-footer
                  >
                    <template v-slot:default="props">
                      <v-list
                        v-for="item in props.items"
                        :key="item.id"
                      >
                        <v-list-item
                          three-line
                        >
                          <v-list-item-action
                            class="align-center"
                          >
                            <v-checkbox
                              v-if="mode.reorganize"
                              :input-value="props.isSelected(item)"
                              @click="props.select(item, !props.isSelected(item))"
                            />
                          </v-list-item-action>
                          <v-list-item-avatar
                            tile
                            size="90"
                            class="mr-3"
                          >
                            <v-img :src="item.product.photo_url" />
                          </v-list-item-avatar>
                        
                          <v-list-item-content
                            class="pa-2"
                          >
                            <v-list-item-title
                              class="font-weight-bold pb-3"
                            >
                              {{ item.product.name }}
                            </v-list-item-title>
                            <v-col
                              cols="12"
                              md="4"
                              sm="12"
                            >
                              <v-row class="lh_product__detail py-2">
                                <span class="pr-2">Boutique : </span><span>{{ item.store.name }}</span>
                              </v-row>
                              <v-row class="lh_product__detail py-2">
                                <span class="pr-2">Tailles : </span><span>{{ item.product.sizes }}</span>
                              </v-row>
                              <v-row class="lh_product__detail py-2">
                                <span class="pr-2">Poids (g) : </span><span>{{ item.product.weight }}</span>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="12"
                              md="4"
                              sm="12"
                            >
                              <v-row class="lh_product__detail py-2">
                                <span class="pr-2">Sku : </span><span>{{ item.product.sku }}</span>
                              </v-row>
                              <v-row class="lh_product__detail py-2">
                                <span class="pr-2">EAN : </span><span>{{ item.product.ean_code }}</span>
                              </v-row>
                              <v-row class="lh_product__detail py-2">
                                <span class="pr-2">Référence Externe : </span>
                                <span>{{ item.product.external_reference }}</span>
                              </v-row>
                              <v-row
                                v-if="item.product.customization"
                                class="lh_product__detail py-2"
                              >
                                <span class="pr-2 font-weight-black">{{ item.product.customization.label }} : </span>
                                <span>{{ item.product.customization.value }}</span>
                              </v-row>
                            </v-col>
                            <v-col
                              cols="12"
                              md="4"
                              sm="12"
                            >
                              <slot />
                            </v-col>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </template>
                    <template v-slot:no-data>
                      <v-row
                        class="fill-height"
                        align-content="center"
                        justify="center"
                      >
                        <v-col
                          class="subtitle-1 text-center"
                          cols="12"
                        >
                          Aucun article
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <ButtonAction
                    v-if="mode.reorganize"
                    :disabled="selected.length < 1"
                    :action="openToNewOneModal"
                    icon="mdi-package-variant-plus"
                    text="Nouveau colis"
                  />
                  <ButtonAction
                    v-if="mode.reorganize"
                    :disabled="selected.length < 1"
                    :action="openToExistingModal"
                    icon="mdi-package-variant-closed-check"
                    text="Colis existant"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <ProviderModal
        :opened.sync="modal.provider.opened"
        :shipment-id="shipment.id"
        :provider="shipment.provider"
        :provider-reference="shipment.provider_reference"
        :carrier="shipment.carrier"
        :tracking-number="shipment.tracking_number"
        @success="s => shipment = s"
      />
      <DeliverModal
        :opened.sync="modal.deliver.opened"
        :shipment-id="shipment.id"
        @success="s => shipment = s"
      />
      <MoveToNewOneModal
        :opened.sync="modal.moveToNewOne.opened"
        :shipment-src="shipment"
        :items="selected"
        @success="s => shipment = s"
      />
      <MoveToExistingOneModal
        :opened.sync="modal.moveToExistingOne.opened"
        :shipment-src="shipment"
        :items="selected"
        @success="s => shipment = s"
      />
      <ConfirmModal ref="confirm" />
    </template>
  </Page>
</template>

<script>
import Address from '@/views/Shipments/Edit/Address.vue'
import ButtonAction from '@/components/base/Toolbar/ButtonAction.vue'
import CarrierChip from '@/components/base/Shipment/CarriererChip.vue'
import DeliverModal from '@/views/Shipments/Edit/DeliverModal.vue'
import MoveToExistingOneModal from '@/views/Shipments/Edit/MoveToExistingOneModal.vue'
import MoveToNewOneModal from '@/views/Shipments/Edit/MoveToNewOneModal.vue'
import Notes from '@/components/core/Notes.vue'
import Page from '@/components/core/Page.vue'
import ProviderChip from '@/components/base/Shipment/ProviderChip.vue'
import ProviderModal from '@/views/Shipments/Edit/ProviderModal.vue'
import shipment from '@/mixins/shipment'
import snackbarMixin from '@/mixins/snackbar'
import StatusChip from '@/components/base/Shipment/StatusChip.vue'
import TrackingNumberLink from '@/components/base/Shipment/TrackingNumberLink.vue'

export default {
  name: 'ShipmentEdit',
  components: {
    ButtonAction,
    Page,
    TrackingNumberLink,
    MoveToNewOneModal,
    MoveToExistingOneModal,
    Notes,
    DeliverModal,
    ProviderModal,
    CarrierChip,
    ProviderChip,
    Address,
    StatusChip,
  },
  mixins: [ shipment, snackbarMixin ],
  data() {
    return {
      shipmentId: this.$route.params.id,
      modal: {
        deliver: {
          opened: false,
        },
        provider: {
          opened: false,
        },
        moveToNewOne: {
          opened: false,
        },
        moveToExistingOne: {
          opened: false,
        },
      },
      message: {
        type: null,
        text: null,
        link: null,
      },
      mode: {
        reorganize: false,
      },
      mounted: false,
      request: null,
      loading: false,
      shipment: {},
      selected: [],
    }
  },
  computed: {
    capacities: function () {
      return {
        updateSender: ['SENDCLOUD'].includes(this.shipment.provider)
            && ['AWAITING_LABEL_GENERATION'].includes(this.shipment.status),
        updateRecipient: !['EXTERNAL', 'BERRY_SERVICES'].includes(this.shipment.provider)
            && ['AWAITING_LABEL_GENERATION', 'PENDING_DEPOSIT'].includes(this.shipment.status)
            && this.shipment.provider_reference === null,
        createParcel: !['EXTERNAL', 'BERRY_SERVICES'].includes(this.shipment.provider)
            && ['AWAITING_LABEL_GENERATION', 'PENDING_DEPOSIT'].includes(this.shipment.status)
            && this.shipment.provider_reference === null,
        createLabel: this.shipment.provider_reference !== null
            && this.shipment.status === 'AWAITING_LABEL_GENERATION'
            && !['EXTERNAL', 'BERRY_SERVICES', 'WING_B2B'].includes(this.shipment.provider),
        downloadLabel: this.shipment.label_url !== null,
        refreshStatus: this.shipment.status !== 'DELIVERED'
            && this.shipment.deleted_at === null,
        updateDelivery: this.shipment.status !== 'DELIVERED'
            && this.shipment.deleted_at === null,
        lost: !this.shipment.lost,
      }
    },
  },
  mounted() {
    this.load(this.$route.params.id)
  },
  methods: {
    cancel() {
      this.message = { type: null, text: null }
      if (this.request) {
        this.request.cancel('aborted')
        this.request = null
      }
    },
    load(id) {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      
      this.loading = true
      this.$axios.get('v3/shipments/' + id, { cancelToken: axiosSource.token })
        .then((response) => {
          this.shipment = response.data
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(error)
          let error_message = error.response.data['hydra:description'] ?? error.response.data['detail'] ?? 'No context'
          this.message.type = 'error'
          this.message.text = this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message })
        })
        .finally(() => {
          this.request = null
          this.loading = false
          this.mounted = true
          this.selected = []
          this.mode.reorganize = false
        })
    },
    async markAsLost() {

      this.loading = true

      if (await this.$refs.confirm.open(
        'Colis perdu',
        'Vous allez déclarer ce colis comme perdu. Êtes-vous sûr ?')
      ) {

        this.cancel()
        let axiosSource = this.$axios.CancelToken.source()
        this.request = { cancel: axiosSource.cancel }
        this.loading = true

        try {
          this.shipment = (await this.$axios.post('v3/shipments/' + this.shipmentId + '/lost', {})).data
        } catch (error) {
          const error_message = error?.response?.data['hydra:description'] ??
              error?.response?.data['detail'] ??
              error
          this.snackbarError(this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message }))
        } finally {
          this.loading = false
          this.request = null
        }
      } else {
        this.loading = false
      }
    },
    async createParcel() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.loading = true

      try {
        this.shipment = (await this.$axios.post('v3/shipments/' + this.shipmentId + '/parcels', {})).data
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message }))
      } finally {
        this.loading = false
        this.request = null
      }
    },
    async  createLabel() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.loading = true

      try {
        this.shipment = (await this.$axios.post('v3/shipments/' + this.shipmentId + '/labels', {})).data

      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message }))
      } finally {
        this.loading = false
        this.request = null
      }
    },
    async refresh() {
      this.cancel()
      let axiosSource = this.$axios.CancelToken.source()
      this.request = { cancel: axiosSource.cancel }
      this.loading = true

      try {
        this.shipment = (await this.$axios.post('v3/shipments/' + this.shipmentId + '/refresh', {})).data
      } catch (error) {
        const error_message = error?.response?.data['hydra:description'] ??
            error?.response?.data['detail'] ??
            error
        this.snackbarError(this.$i18n.t('views.shipment.forms.messages.save_failed', { error: error_message }))
      } finally {
        this.loading = false
        this.request = null
      }
    },
    openDeliverModal() {
      this.modal.deliver.opened = true
    },
    openProviderModal() {
      this.modal.provider.opened = true
    },
    activeReorganizeMode() {
      this.mode.reorganize = true
    },
    openToNewOneModal() {
      this.modal.moveToNewOne.opened = true
    },
    openToExistingModal() {
      this.modal.moveToExistingOne.opened = true
    },
  },
}
</script>

<style scoped>
.lh_shipment__details tr td:first-child {
  font-weight: bolder;
}
</style>
